import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import '../scss/advertising.scss';
// hooks
import useMediaQuery from '../hooks/useMediaQuery';
// lib
import handlePageTransition from '../lib/handle-page-transition';
// components
import SEO from '../components/SEO';
import AdvertisingContent from '../components/advertising/AdvertisingContent';
import MobileAdvertisingContent from '../components/advertising/AdvertisingContentMobile';


function Advertising({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  return (
    <div>
      <SEO title="Advertising | Falkon Content" />
      <div className="advertising">
      {isDesktop
          ? (<AdvertisingContent />)
          : (<MobileAdvertisingContent />)
        }
      </div>
    </div>
  );
}

export default React.memo(Advertising);
