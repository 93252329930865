import React from 'react';
// components
import MLBTout from './MLBTout';
import WNZ3Tout from './WNZ3Tout';
import UniteCapsulesTout from './UniteCapsulesTout';
import TheOnesTout from './TheOnesTout';
// import RiseChicagoTout from './RiseChicagoTout';
import RealTalkTout from './RealTalkTout';
import ThePlaygroundTout from './ThePlaygroundTout';
import McEnroeTout from '../home/McEnroeTout';

function AdvertisingContent() {
  // const scrollOffset = [40, 0];
  const scrollOffset = [0, 0];

  return (
    <div>
      <McEnroeTout
        bgColor="#080000"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <RealTalkTout
        bgColor="#000"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <MLBTout
        bgColor="#A4272A"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <WNZ3Tout
        bgColor="#f08f7c"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <UniteCapsulesTout
        bgColor="#e1b9af"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <TheOnesTout
        autoPlayVideo={true}
        bgColor="#507d9b"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      {/* <RiseChicagoTout
        autoPlayVideo={true}
        bgColor="#2f43a3"
        scrollOffset={scrollOffset}
        textColor="#fff"
      /> */}
      <ThePlaygroundTout
        autoPlayVideo={true}
        bgColor="#213075"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
    </div>
  )
}

export default AdvertisingContent;