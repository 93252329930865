import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';
import AutoPlayVideo from '../AutoPlayVideo';
import VideoPlayer from '../VideoPlayer';

function ThePlaygroundTout({ autoPlayVideo, bgColor, scrollOffset, textColor }) {
  const data = useStaticQuery(graphql`
    query AdvThePlaygroundTout {
      allCloudinaryMedia(
        filter: { tags: { eq: "the-playground-tout" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
      allVimeo(
        filter: { name: { regex: "/THE_PLAYGROUND_TOUT/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            srcset {
              link
            }
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const vimeoMedia = data.allVimeo.edges;
  const images = [];
  const videos = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  vimeoMedia.forEach(item => {
    const { srcset } = item.node;
    videos.push(srcset);
  });
  const [img01, img02] = images;
  // const [caseStudy14] = videos;
  const caseStudy14 =
    'https://player.vimeo.com/external/433463274.sd.mp4?s=efb2a4aae3c7ffe7337823fc186737b0c265d885&profile_id=165&oauth2_token_id=1365998561';
  const caseStudy14Poster =
    'https://i.vimeocdn.com/video/915964434_1080x1080.jpg?r=pad';

  return (
    <SectionTrigger
      className="tout the-playground-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
      <ToutCaption
        modifier="the-playground-tout"
        title="The Playground"
        logo="jumpman"
        link="/case-studies/the-playground"
      />
      {autoPlayVideo ?
        (
          <AutoPlayVideo
            className="the-playground-tout__vid the-playground-tout__vid--1"
            poster={caseStudy14Poster}
            src={caseStudy14}
          />
        ) : (
          <VideoPlayer
            className="the-playground-tout__vid the-playground-tout__vid--1"
            poster={caseStudy14Poster}
            src={caseStudy14}
          />
        )
      }
      <Parallax
        className="the-playground-tout__img the-playground-tout__img--1"
        y={scrollOffset}
      >
        <ParallaxImage src={img01} alt="" />
      </Parallax>
      <div className="the-playground-tout__img the-playground-tout__img--2">
        <img src={img02} alt="" />
      </div>
    </SectionTrigger>
  );
}

export default React.memo(ThePlaygroundTout);
