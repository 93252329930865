import React from 'react';

export function YoutubeLogo() {
  return (
    <svg id="YouTube-Almost-Black-Logo.wine" xmlns="http://www.w3.org/2000/svg" width="156.365" height="34.908" viewBox="0 0 156.365 34.908">
        <path id="Path_14" data-name="Path 14" d="M201.113,33.9a10.657,10.657,0,0,0,.41-3.494V23.6a10.537,10.537,0,0,0-.41-3.441,1.467,1.467,0,0,0-1.444-1.088,1.447,1.447,0,0,0-1.409,1.088,10.529,10.529,0,0,0-.41,3.441v6.811a11.09,11.09,0,0,0,.393,3.494,1.433,1.433,0,0,0,1.426,1.07A1.464,1.464,0,0,0,201.113,33.9Zm-5.527,3.424a5.428,5.428,0,0,1-2.1-3.1,20.3,20.3,0,0,1-.624-5.6V25.451a18.4,18.4,0,0,1,.713-5.67,5.685,5.685,0,0,1,2.229-3.12,7.218,7.218,0,0,1,3.976-.981,6.782,6.782,0,0,1,3.887,1A5.725,5.725,0,0,1,205.8,19.8a19.146,19.146,0,0,1,.677,5.652v3.174a19.467,19.467,0,0,1-.659,5.616,5.514,5.514,0,0,1-2.14,3.1,8.771,8.771,0,0,1-8.095-.018m76.367-17.85a3.009,3.009,0,0,0-.5,1.516,29.54,29.54,0,0,0-.124,3.192v1.569h3.6V24.183a26.588,26.588,0,0,0-.143-3.192,3.059,3.059,0,0,0-.517-1.533,1.43,1.43,0,0,0-1.159-.446,1.4,1.4,0,0,0-1.159.464Zm-.623,9.2v1.106a29.073,29.073,0,0,0,.124,3.156,2.991,2.991,0,0,0,.517,1.534,1.473,1.473,0,0,0,1.212.482A1.526,1.526,0,0,0,274.7,34.1a7.452,7.452,0,0,0,.445-2.853l4.244.25a6.7,6.7,0,0,1,.036.784,5.8,5.8,0,0,1-1.658,4.529,6.755,6.755,0,0,1-4.689,1.5q-3.637,0-5.1-2.283t-1.462-7.061V25.146q0-4.921,1.516-7.186t5.189-2.264a6.855,6.855,0,0,1,3.887.927,4.978,4.978,0,0,1,1.908,2.889,20.874,20.874,0,0,1,.553,5.421v3.745H271.33M183.6,28.268,178,8.047h4.886l1.961,9.165q.749,3.389,1.106,5.777h.143q.249-1.711,1.106-5.742l2.033-9.2h4.886l-5.67,20.221v9.7H183.6v-9.7m38.768-12.16V37.968h-3.852l-.428-2.675h-.107a5.035,5.035,0,0,1-4.708,3.031,3.7,3.7,0,0,1-3.209-1.426,7.657,7.657,0,0,1-1.034-4.458V16.108h4.921V32.156a4.894,4.894,0,0,0,.321,2.086,1.109,1.109,0,0,0,1.07.624,2.179,2.179,0,0,0,1.23-.393,2.429,2.429,0,0,0,.874-1V16.108h4.922m25.243,0V37.968h-3.852l-.428-2.675h-.106a5.036,5.036,0,0,1-4.708,3.031,3.7,3.7,0,0,1-3.21-1.426,7.657,7.657,0,0,1-1.034-4.458V16.108H239.2V32.156a4.9,4.9,0,0,0,.32,2.086,1.11,1.11,0,0,0,1.07.624,2.178,2.178,0,0,0,1.23-.393,2.429,2.429,0,0,0,.874-1V16.108h4.921" transform="translate(-123.202 -5.57)" fill="#f7f0f0"/>
        <path id="Path_15" data-name="Path 15" d="M112.541,6.437h-4.886V32.4h-4.814V6.437H97.956V2.478h14.585V6.437M136.189,22.95a27.643,27.643,0,0,1-.2,3.744,4.037,4.037,0,0,1-.66,1.926,1.525,1.525,0,0,1-1.248.571,2.279,2.279,0,0,1-1.124-.285,2.154,2.154,0,0,1-.838-.856V15.638a3.113,3.113,0,0,1,.856-1.462,1.9,1.9,0,0,1,1.319-.57,1.332,1.332,0,0,1,1.159.588,4.532,4.532,0,0,1,.57,1.98,36.857,36.857,0,0,1,.161,3.958Zm4.511-8.917a5.624,5.624,0,0,0-1.445-3,3.88,3.88,0,0,0-2.746-.927,4.552,4.552,0,0,0-2.532.767,5.211,5.211,0,0,0-1.819,2.015h-.036V1.372H127.38V32.4h4.065l.5-2.068h.107a4.176,4.176,0,0,0,1.712,1.747,5.075,5.075,0,0,0,2.532.642,3.876,3.876,0,0,0,3.673-2.3q1.177-2.3,1.177-7.186V19.774a28.732,28.732,0,0,0-.446-5.742M48.826,5.451a6.245,6.245,0,0,0-4.409-4.409C40.528,0,24.934,0,24.934,0S9.34,0,5.451,1.042A6.245,6.245,0,0,0,1.042,5.451C0,9.34,0,17.454,0,17.454s0,8.114,1.042,12a6.245,6.245,0,0,0,4.409,4.409c3.889,1.042,19.483,1.042,19.483,1.042s15.594,0,19.483-1.042a6.245,6.245,0,0,0,4.409-4.409c1.042-3.889,1.042-12,1.042-12s0-8.114-1.042-12" fill="#f7f0f0"/>
        <path id="Path_16" data-name="Path 16" d="M64.792,47.356V32.4l12.956,7.48Z" transform="translate(-44.845 -22.422)" fill="#dfc099"/>
    </svg>
  );
}
