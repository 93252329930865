import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';

function WNZ3Tout({ bgColor, scrollOffset, textColor }) {
  const data = useStaticQuery(graphql`
    query AdvWNZ3Tout {
      allCloudinaryMedia(filter: { tags: { eq: "wnz3-tout" } }) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  const [img01, img02, img03] = images;
  return (
    <SectionTrigger
      className="tout wnz3-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
      <ToutCaption
        modifier="wnz3-tout"
        title="WNZ.3"
        link="/case-studies/wnz3"
        logo="jordan"
      />
      <div className="wnz3-tout__img wnz3-tout__img--1">
        <img src={img01} alt="no alt" />
      </div>
      <Parallax className="wnz3-tout__img wnz3-tout__img--2" y={scrollOffset}>
        <ParallaxImage src={img02} alt="no alt" />
      </Parallax>
      <div className="wnz3-tout__img wnz3-tout__img--3">
        <img src={img03} alt="no alt" />
      </div>
    </SectionTrigger>
  );
}

export default React.memo(WNZ3Tout);
