import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';
import AutoPlayVideo from '../AutoPlayVideo';
import VideoPlayer from '../VideoPlayer';

function TheOnesTout({ autoPlayVideo, bgColor, scrollOffset, textColor }) {
  const data = useStaticQuery(graphql`
    query AdvTheOnesTout {
      allCloudinaryMedia(
        filter: { tags: { eq: "the-ones-tout" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
      allVimeo(
        filter: { name: { regex: "/THE_ONES_TOUT/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            srcset {
              link
            }
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const vimeoMedia = data.allVimeo.edges;
  const images = [];
  const videos = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  vimeoMedia.forEach(item => {
    const { srcset } = item.node;
    videos.push(srcset);
  });
  const [img01] = images;
  // const [aj9, theOnes10] = videos;
  const aj9 =
    'https://player.vimeo.com/external/433467718.hd.mp4?s=d4bbffebfb340f33066f1eb8b4ac900e44b79d00&profile_id=175&oauth2_token_id=1365998561';
  const aj9Poster =
    'https://i.vimeocdn.com/video/915972183_1920x1080.jpg?r=pad';
  const theOnes10 =
    'https://player.vimeo.com/external/433467741.hd.mp4?s=9889af8858ee65fd476e27573c240b98ac97a64e&profile_id=174&oauth2_token_id=1365998561';
  const theOnes10Poster =
    'https://i.vimeocdn.com/video/915972165_1366x684.jpg?r=pad';

  return (
    <SectionTrigger
      className="tout the-ones-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
      <ToutCaption
        modifier="the-ones-tout"
        title="&#34;The Ones&#34;"
        logo="jumpman"
        link="/case-studies/the-ones"
      />
      <Parallax
        className="the-ones-tout__img the-ones-tout__img--1"
        y={scrollOffset}
      >
        <ParallaxImage src={img01} alt="" />
      </Parallax>
      <div style={{ position: 'absolute' }}>
        {autoPlayVideo ?
          (
            <AutoPlayVideo
              className="the-ones-tout__vid the-ones-tout__vid--2"
              poster={aj9Poster}
              src={aj9}
            />
          ) : (
            <VideoPlayer
              className="the-ones-tout__vid the-ones-tout__vid--2"
              poster={aj9Poster}
              src={aj9}
            />
          )
        }
      </div>

      <div style={{ position: 'absolute' }}>
        {
          autoPlayVideo ?
          (
            <AutoPlayVideo
              className="desktop-only the-ones-tout__vid the-ones-tout__vid--1"
              poster={theOnes10Poster}
              src={theOnes10}
            />
          ) : (
            <VideoPlayer
              className="desktop-only the-ones-tout__vid the-ones-tout__vid--1"
              poster={theOnes10Poster}
              src={theOnes10}
            />
          )
        }
      </div>
    </SectionTrigger>
  );
}

export default TheOnesTout;
