import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';

function McEnroeTout({ bgColor, scrollOffset, textColor }) {
  const data = useStaticQuery(graphql`
    query HomeMcEnroeTout {
      allCloudinaryMedia(
        filter: { tags: { eq: "mcenroe-tout" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  const [img01, img02, img03] = images;

  return (
    <SectionTrigger
      className="tout mcenroe-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
      <ToutCaption
        // anchorLink={true}
        // link="/entertainment#mcenroe"
        link="/case-studies/mcenroe"
        modifier="mcenroe-tout"
        subtitle="Michelob Ultra"
        title="Mcenroe vs Mcenroe"
      />
      <div className="mcenroe-tout__img mcenroe-tout__img--1">
        <img src={img01} alt="" />
      </div>
      <div className="mcenroe-tout__img mcenroe-tout__img--2">
        <img src={img02} alt="" />
      </div>
      <Parallax className="mcenroe-tout__img mcenroe-tout__img--3" y={scrollOffset}>
        <ParallaxImage src={img03} alt="" />
      </Parallax>
    </SectionTrigger>
  );
}

export default React.memo(McEnroeTout);
