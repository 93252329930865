import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Swipe from '../lib/swipe';

import {
  JumpmanIcon,
  MLBLogo,
  NikeIcon,
  TheNorthFaceIcon,
  SonosIcon,
} from './svgs';
import { YoutubeLogo } from './svgs/YoutubeLogo';

function SwipeDefault({ to, children, direction, className }) {
  return (
    <Swipe
      to={to}
      swipe
      direction={direction}
      top="exit"
      entryOffset={100}
      duration={2}
      className={className}
      activeClassName="is-active"
    >
      {children}
    </Swipe>
  );
}

function ImageTout({ title, subtitle, image, link, imageAlt }) {
  return (
    <>
      <img className="tout-caption__logo" src={image} alt={imageAlt} />
      {subtitle && <p className="tout-caption__subtitle">{subtitle}</p>}
      {link ? (
        <SwipeDefault className="tout-caption__link" direction="left" to={link}>
          <h3 className="tout-caption__title">{title}</h3>
        </SwipeDefault>
      ) : (
        <h3 className="tout-caption__title">{title}</h3>
      )}
    </>
  );
}

function LogoTout({ title, subtitle, logo, link }) {
  let Logo;
  if (logo === 'tnf') {
    Logo = <TheNorthFaceIcon modifier="tout-caption__logo" />;
  } else if (logo === 'nike') {
    Logo = <NikeIcon modifier="tout-caption__logo" />;
  } else if (logo === 'sonos') {
    Logo = <SonosIcon modifier="tout-caption__logo" />;
  } else if (logo === 'mlb') {
    Logo = <MLBLogo modifier="tout-caption__logo" />;
  } else if (logo === 'youtube') {
    Logo = <YoutubeLogo modifier="tout-caption__logo" />;
  } else {
    Logo = <JumpmanIcon modifier="tout-caption__logo" />;
  }
  return (
    <>
      {Logo}
      {subtitle && <p className="tout-caption__subtitle">{subtitle}</p>}
      {link ? (
        <SwipeDefault className="tout-caption__link" direction="left" to={link}>
          <h3 className="tout-caption__title">{title}</h3>
        </SwipeDefault>
      ) : (
        <h3 className="tout-caption__title">{title}</h3>
      )}
    </>
  );
}

function TextTout({ title, subtitle, link }) {
  return (
    <>
      {subtitle && <p className="tout-caption__subtitle">{subtitle}</p>}
      {link ? (
        <SwipeDefault className="tout-caption__link" direction="left" to={link}>
          <h3 className="tout-caption__title">{title}</h3>
        </SwipeDefault>
      ) : (
        <h3 className="tout-caption__title">{title}</h3>
      )}
    </>
  );
}

function TextStrokeTout({ link, subtitle, title, textStroke }) {
  return (
    <>
      {subtitle && <p className="tout-caption__subtitle">{subtitle}</p>}
      {link ? (
        <SwipeDefault className="tout-caption__link" direction="left" to={link}>
          <h3 className="tout-caption__title">{title}<span>{textStroke}</span></h3>
        </SwipeDefault>
      ) : (
        <h3 className="tout-caption__title">{title}<span>{textStroke}</span></h3>
      )}
    </>
  );
}

function TextStrokeAnchorTout({ link, subtitle, title, textStroke }) {
  return (
    <>
      {subtitle && <p className="tout-caption__subtitle">{subtitle}</p>}
      {link ? (
        <AnchorLink
          className="tout-caption__link"
          title={`${title} ${textStroke}`}
          to={link}
        >
          <h3 className="tout-caption__title">{title}<span>{textStroke}</span></h3>
        </AnchorLink>
      ) : (
        <h3 className="tout-caption__title">{title}<span>{textStroke}</span></h3>
      )}
    </>
  );
}


function ToutCaption(props) {
  const { anchorLink, className, modifier, title, subtitle, link, image, logo, textStroke, imageAlt } = props;
  let Tout;
  let toutClassName;
  if (image) {
    Tout = (
      <ImageTout
        title={title}
        subtitle={subtitle}
        link={link}
        image={image}
        imageAlt={imageAlt}
      />
    );
  } else if (logo) {
    Tout = (
      <LogoTout
        link={link}
        logo={logo}
        subtitle={subtitle}
        title={title}
      />
    );
  } else if (anchorLink) {
    Tout = (
      <TextStrokeAnchorTout
        link={link}
        subtitle={subtitle}
        textStroke={textStroke}
        title={title}
      />
    );
  } else if (textStroke) {
    Tout = (
      <TextStrokeTout
        link={link}
        subtitle={subtitle}
        textStroke={textStroke}
        title={title}
      />
    );
  } else {
    Tout = (
      <TextTout
        title={title}
        subtitle={subtitle}
        link={link}
      />
    );
  }

  if (className && modifier) {
    toutClassName = `${className} tout-caption ${modifier}__tout-caption`
  } else if (className) {
    toutClassName = `${className} tout-caption`
  } else if (modifier) {
    toutClassName = `tout-caption ${modifier}__tout-caption`
  } else {
    toutClassName = 'tout-caption'
  }

  return (
    <div className={toutClassName}>
      {Tout}
    </div>
  );
}

export default ToutCaption;
