import React from 'react';
// components
import MLBToutMobile from './MLBToutMobile';
import WNZ3ToutMobile from './WNZ3ToutMobile';
import UniteCapsulesToutMobile from './UniteCapsulesToutMobile';
import TheOnesToutMobile from './TheOnesToutMobile';
// import RiseChicagoToutMobile from './RiseChicagoToutMobile';
import RealTalkToutMobile from './RealTalkToutMobile';
import ThePlaygroundToutMobile from './ThePlaygroundToutMobile';
import McEnroeToutMobile from '../home/McEnroeToutMobile';

function AdvertisingContentMobile() {
  // const scrollOffset = [-20, 0];
  const scrollOffset = [0, 0];

  return (
    <div>
      <McEnroeToutMobile
        bgColor="#080000"
        scrollOffset={[0, 0]}
        textColor="#fff"
      />
      <RealTalkToutMobile
        bgColor="#000"
        scrollOffset={scrollOffset}
        textColor="#fff"
       />
      <MLBToutMobile
        bgColor="#A4272A"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <WNZ3ToutMobile
        bgColor="#f08f7c"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <UniteCapsulesToutMobile
        bgColor="#e1b9af"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <TheOnesToutMobile
        autoPlayVideo={true}
        bgColor="#507d9b"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      {/* <RiseChicagoToutMobile
        autoPlayVideo={true}
        bgColor="#2f43a3"
        scrollOffset={scrollOffset}
        textColor="#fff"
      /> */}
      <ThePlaygroundToutMobile
        autoPlayVideo={true}
        bgColor="#213075"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
    </div>
  )
}

export default AdvertisingContentMobile;