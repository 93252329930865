import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';

function UniteCapsulesTout({ bgColor, scrollOffset, textColor }) {
  const data = useStaticQuery(graphql`
    query AdvUniteCapsulesTout {
      allCloudinaryMedia(filter: { tags: { eq: "unite-capsules-tout" } }) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  const [img01, img02, img03] = images;

  return (
    <SectionTrigger
      className="tout uc-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
      <ToutCaption
        modifier="uc-tout"
        title="Unite Capsules"
        link="/case-studies/unite-capsules"
        logo="jordan"
      />
      <Parallax
        className="uc-tout__img uc-tout__img--1"
        y={scrollOffset}
      >
        <ParallaxImage src={img01} alt="Virgil" />
      </Parallax>
      {/* [418] neymar - scrub from site */}
      <div className="uc-tout__img uc-tout__img--2 hide">
        <img src={img02} alt="Neymar" />
      </div>
      <div className="uc-tout__img uc-tout__img--3">
        <img src={img03} alt="Zion" />
      </div>
    </SectionTrigger>
  );
}

export default React.memo(UniteCapsulesTout);
