import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';

function ThePlaygroundToutMobile({ bgColor, scrollOffset, textColor }) {
  const data = useStaticQuery(graphql`
    query AdvThePlaygroundToutMobile {
      allCloudinaryMedia(
        filter: { tags: { eq: "the-playground-tout-mobile" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  const [img01] = images;

  return (
    <SectionTrigger
      className="tout the-playground-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
      <ToutCaption
        modifier="the-playground-tout"
        title="The Playground"
        logo="jumpman"
        link="/case-studies/the-playground"
      />
      <Parallax className="the-playground-tout__img the-playground-tout__img--1" y={scrollOffset}>
        <ParallaxImage src={img01} alt="" />
      </Parallax>
    </SectionTrigger>
  );
}

export default React.memo(ThePlaygroundToutMobile);
